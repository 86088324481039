@import url("https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&family=VT323&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html {
  margin: 0;
  padding: 0;
}

* {
  font-family: "Minecraftia", "VT323";
  box-sizing: border-box;
  caret-color: transparent;
}

body::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
}

html {
  scrollbar-width: none;
}

@font-face {
  font-family: "Minecraftia";
  src: url("../assets/fonts/minecraft-font/Minecraft.eot");
  src: url("../assets/fonts/minecraft-font/Minecraft.woff2") format("woff2"),
    url("../assets/fonts/minecraft-font/Minecraft.woff") format("woff"),
    url("../assets/fonts/minecraft-font/minecraft_font.ttf") format("truetype"),
    url("../assets/fonts/minecraft-font/Minecraft.svg") format("svg");
}
